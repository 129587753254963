<template>
  <div id="motor-list">
    <div class="options w-100 py-2">
      <div class="d-inline-block text-center">
        <router-link :to="'/main/motors/'">
          <div class="icon mx-2">
            <!-- <span class="ukazatel-alertu" v-if="pocetVystrah>0">{{pocetVystrah}}</span> -->
            <div class="active-line"></div>
            <img src="/img/moje_karty.png" alt />
          </div>
          <div class="mt-1">{{$t('option.moje-karty')}}</div>
        </router-link>
      </div>

      <div class="d-inline-block text-center">
        <router-link to="/groups">
          <div class="icon mx-2">

            <div class="active-line"></div>
            <img src="/img/group-50.png" alt />
          </div>
          <div class="mt-1">{{$t('menu.team')}}</div>

        </router-link>

      </div>
<!--
      <div class="d-inline-block text-center">
        <a href="#">
          <div class="icon mx-2">
            <div class="active-line"></div>
            <img src="/img/karty_skupin.png" alt />
          </div>
          <div class="mt-1">{{$t('option.karty-skupiny')}}</div>
        </a>
      </div>

      <div class="d-inline-block text-center">
        <a href="#">
          <div class="icon mx-2">
            <div class="active-line"></div>
            <img src="/img/hledat.png" alt />
          </div>
          <div class="mt-1">{{$t('option.hledat-motor')}}</div>
        </a>
      </div>-->
    </div>

    <div class="list w-100 py-2 px-1">
      <h4 class="h6 px-1 pt-1">{{$t('moje-motory')}}:</h4>
      <div v-if="motors.length<1">
        {{$t('motor.no-motors')}}
      </div>
      <MotorItem v-for="motor in motors_sorted" :key="motor.token" :motor="motor"></MotorItem>
    </div>
  </div>
</template>

<script>
//const qs = require("qs");
import MotorItem from "./MotorItem";
const axios = require("axios");
axios.defaults.withCredentials = true;

import motorsMixin from "@/mixins/motorsMixin";


export default {
  name: "SeznamMotoru",
  components: {MotorItem},
  mixins: [motorsMixin],
  data() {
    return {
      motors:[],
      sortparams:{}

    };
  },

  mounted() {

    if (this.$store.getters.me.company && this.$store.getters.me.company.status=='rejected'  && this.$route.name!='nonconfirmed'){
      this.$router.push('/main/non-confirmed')
      return
    }

    //console.log("mounted seznam motoru ", this.$route.query.user_id);
    this.loadMotors()
  },

};
</script>

<style>
  .ukazatel-alertu{
    position:absolute;
    background: #fab20a;
    color: #140000;
    width:18px;
    height:18px;
    line-height: 17px;
    border-radius:9px;
    right:2px;
    top:2px;
  }
</style>
